<template>
  <div class="home-cont">
    <div class="h-name">服务商及合作伙伴入驻申请</div>
    <ul class="h-list">
      <li @click="goPath('/join/stores')">
        <div class="l">
          <img src="@/assets/image/join/1.png" alt="">
        </div>
        <div class="r">
          <div class="r-name">
            <div class="r-name-text">卖场入驻</div>
            <div class="r-name-line"></div>
          </div>
          <div class="r-title">延保服务入驻线下卖场</div>
        </div>
        <div class="more">
          <right theme="outline" size="24" fill="#333" :strokeWidth="2"/>
        </div>
      </li>
      <li @click="goPath('/join/service')">
        <div class="l">
          <img src="@/assets/image/join/2.png" alt="">
        </div>
        <div class="r">
          <div class="r-name">
            <div class="r-name-line"></div>
            <div class="r-name-text">服务站入驻</div>
          </div>
          <div class="r-title">加入我们的维修服务体系</div>
        </div>
        <div class="more">
          <right theme="outline" size="24" fill="#333" :strokeWidth="2"/>
        </div>
      </li>
      <li @click="goPath('/join/agent')">
        <div class="l">
          <img src="@/assets/image/join/3.png" alt="">
        </div>
        <div class="r">
          <div class="r-name">
            <div class="r-name-text">代理申请</div>
            <div class="r-name-line"></div>
          </div>
          <div class="r-title">各类合作及销售代理</div>
        </div>
        <div class="more">
          <right theme="outline" size="24" fill="#333" :strokeWidth="2"/>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {Right} from '@icon-park/vue'

export default {
  name: 'Home',
  components: {
    Right
  },
  data () {
    return {

    }
  },
  mounted () {
    
  },
  methods: {
    /**
     * 跳转
     */
    goPath (path) {
      this.$router.push(path)
    },
    noPath () {
      this.$createDialog({
          type: 'alert',
          title: '',
          content: '此功能暂未开通',
          icon: ''
        }).show()
    },
  },
}
</script>

<style lang="scss" scoped>
.home-cont{
  min-height: 100.1vh;
  background-image: url(../../assets/image/join/bg.png);
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.h-name{
  color: #fff;
  text-align: center;
  font-size: 42px;
  line-height: 62px;
  padding-top: 100px;
  padding-bottom: 90px;
}
.h-list{
  padding: 0 30px;
  li{
    background-color: #fff;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    height: 200px;
    box-shadow: 0 7px 16px rgba($color: #000000, $alpha: 0.1);
  }
  .l{
    width: 220px;
    img{
      display: block;
      height: 98px;
      margin: 0 auto;
    }
  }
  .r{
    flex: 10;
    .r-name{
      display: inline-block;
      position: relative;
      z-index: 2;
    }
    .r-name-text{
      font-size: 32px;
      font-weight: 700;
      color: #333333;
      line-height: 56px;
      z-index: 2;
    }
    .r-name-line{
      position: absolute;
      height: 10px;
      left: 0;
      background-color: rgba(233, 169, 32, 0.6);
      width: 100%;
      bottom: 12px;
      z-index: 0;
    }
    .r-title{
      font-size: 28px;
      color: #a7a7a7;
      line-height: 46px;
    }
  }
  .more{
    padding: 0 40px;
  }
}
</style>